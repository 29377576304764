import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

export class AuthAPIService {
  getToken (user) {
    const url = apiBaseUrl + `/auth/token`
    return axios.post(url, user)
  }

  refreshToken (refreshToken) {
    const url = apiBaseUrl + `/auth/token/refresh`
    return axios.post(url, {'refresh': refreshToken})
  }


  modifyPassword (token, old_password, new_password, new_password_confirmed) {
    const url = apiBaseUrl + '/modify-password'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json'
    }
    var data = {
      'old_password': old_password,
      'new_password': new_password,
      'new_password_confirmed': new_password_confirmed,
    }

    return axios.put(
      url, data,
      {
        'headers': headers,
      }
    )
  }

  resetPassword (email) {
    const url = apiBaseUrl + `/password-reset/`
    var headers = {
      'Content-Type': 'application/json'
    }
    return axios.post(
      url,
      {'email': email},
      headers
    )
  }

  confirmResetPassword (token, password) {
    const url = apiBaseUrl + `/password-reset/confirm/`
    var headers = {
      'Content-Type': 'application/json'
    }
    return axios.post(
      url,
      {
        'token': token,
        'password': password
      },
      headers
    )
  }
}
