<template>
  <div id="app">
    <b-navbar class="base_navbar"  toggleable="md">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-navbar-brand :to="{ path: '/' }">
        <img id='brand_logo' :src="require('@/assets/images/logo_audiens.svg')" alt="Audiens - Bourse d'études">
      </b-navbar-brand>

      <b-collapse is-nav id="nav_collapse" >
        <b-navbar-nav v-if="isAuthenticated" class="ml-auto">
          <b-nav-item v-if="userGroupContains(['BENEFICIAIRE'])" class="navbar_items" to="/beneficiaire-dashboard"> Dépôt et suivi de mes demandes </b-nav-item>
          <b-nav-item v-if="userGroupContains(['CONSEILLER'])" class="navbar_items" to="/conseiller-dashboard"> Tableau de bord Conseiller</b-nav-item>
          <b-nav-item-dropdown  class="navbar_items" text="Profil" right>
            <b-dropdown-item v-if="userGroupContains(['BENEFICIAIRE'])"  class="navbar_dropdown_items" to="/settings/profile-edition">Modifier votre profil</b-dropdown-item>
            <b-dropdown-item class="navbar_dropdown_items" to="/settings/modify-password">Modifier votre mot de passe</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item v-if="userGroupContains(['BENEFICIAIRE'])" class="navbar_items" to="/conditions-attribution"> Conditions d'attribution</b-nav-item>

          <b-nav-item-dropdown v-if="userGroupContains(['BENEFICIAIRE'])" class="navbar_items" text="Aide" right>
            <b-dropdown-item class="navbar_items" to="/aide">Aide générale </b-dropdown-item>
            <b-dropdown-item class="navbar_items" to="/aide-detaillee">Guide pas-à-pas  </b-dropdown-item>
            <b-dropdown-item class="navbar_items" to="/faq">Foire aux questions</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item class="navbar_items" to="/logout">Se déconnecter</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav v-else class="ml-auto">
          <b-nav-item class="navbar_items" to="/conditions-attribution"> Conditions d'attribution</b-nav-item>
          <b-nav-item-dropdown class="navbar_items" text="Aide" right>
            <b-dropdown-item class="navbar_items" to="/aide">Aide générale </b-dropdown-item>
            <b-dropdown-item class="navbar_items" to="/aide-detaillee">Guide pas-à-pas  </b-dropdown-item>
            <b-dropdown-item class="navbar_items" to="/faq">Foire aux questions</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item class="navbar_items" to="/login">Se connecter</b-nav-item>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.getters.TOKEN
    }
  },
  methods: {
    userGroupContains(groupsList) {
      var userGroups = this.$store.getters.getUserGroups
      var userGroupsArray = []
      var is_contained = false
      if (userGroups) {
        try {
          var userGroupsJson = JSON.parse(userGroups)
          for (var i = 0; i < userGroupsJson.length; i++) {
            userGroupsArray.push(
              userGroupsJson[i]['name']
            )
          }
        }
        catch(error) {
          this.$store.dispatch('AUTH_LOGOUT').then(() => {
            this.$router.push('/login')
            this.$bvToast.toast(`Votre session a expiré, veuillez vous reconnecter`, {
              title: 'Session expirée',
              autoHideDelay: 7000,
              appendToast: false
            })
          })
          return is_contained
        }
      }

      for (var j = 0; j < groupsList.length; j++) {
        if (userGroupsArray.indexOf(groupsList[j]) > -1) {
          is_contained = true
        }
      }
      return is_contained
    }
  }
}
</script>

<style>
html, body { overflow-x: hidden; }

body {
  font-family: Open Sans, sans-serif !important;
  padding-right: 0 !important;
}
.masthead {
  padding-top: 2%;
  padding-bottom: 2%;
}

.justify-text {
  text-align: justify;
}


#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */

}

.base_navbar {
  height: 60px;
  background-color: white;
}
#brand_logo {
  height: 35px;
}

#nav_collapse {
  background-color: white;
  z-index: 1001;
}

.navbar_items {
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
}

.nav-link {
  color: rgba(0, 0, 0, 1) !important;
}


@media (min-width: 480px) {
  #brand_logo {
    height: 40px;
  }
}

.audiens-color {
  color: #BB3634
}

@media (min-width: 1024px) {
  .base_navbar {
    height: 80px !important;
  }

  #brand_logo {
    height: 45px;
  }
  .navbar_items {
    font-size: 18px;
  }
}

div.mandatory-input label:after {
  content:"*";
  color:red;
}

.form_div {
  background: #FFFFFF;
  padding-left: 15px;
  padding-bottom: 20px;
  border-style: solid;
  border-color: #BDBDBD;
  border-width: 0.5px;
  margin-top: 15px;
}

.form_div_title{
  color: #FFFFFF;
  font-size: calc(1.2em + 0.8vw);
  margin-top: -90px;
  font-family: Montserrat Subrayada, sans-serif !important;
}

.form_content {
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.form_content h2 {
  color: #BB3634;
  margin-top:30px;
  font-weight: bold;
  font-size: calc(1.2em + 0.2vw);
}

.form_content h3 {
  font-weight: bold;
  color: #6E6E6E;
  margin-top:10px;
  font-size: calc(0.9em + 0.4vw);
}

.card-header {
  font-weight: bold;
  color: #B43104;
  font-size: calc(0.9em + 0.5vw);
}

.form-error {
  color: #B1060A;
  font-size: 0.8em;
  font-style: italic;
}

.footer-items {
  font-size: 1.2em;
}

.presentation_content h1 {
  font-weight: bold;
  color: #B43104;
  margin-top:10px;
  font-size: calc(0.9em + 0.5vw);
}

.presentation_content h2 {
  font-weight: bold;
  color: #B43104;
  margin-top:10px;
  font-size: calc(0.8em + 0.4vw);
}
</style>
