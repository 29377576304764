<template>
  <div class="home">
    <section id='home-banner' :style="{ backgroundImage: 'url(' + require('@/assets/images/audiens-bourse-etudes-background.png') + ')' }">
    </section>
    <section id='home-title' class="mt-4">
      <div class="container-md">
        <div class="row">
          <div class="col-12 text-center">
            <h1 class="banner-title">
              Bourse d'études
            </h1>
            <p class="banner-slogan mt-2 mt-md-3">
              <strong>Vous souhaitez bénéficier d’une bourse d’études pour vos enfants étudiants ?</strong><br>
              <span>
                Votre enfant prépare un diplôme reconnu par l’Education Nationale de niveau supérieur
                (hors cours du CNED) ou un cursus professionnel de second cycle occasionnant
                des frais spécifiques ? Audiens Santé Prévoyance peut vous accorder une allocation d’études sous certaines conditions (ressources du foyer,
                composition familiale, âge de l’enfant, situation fiscale…).
              </span>
            </p>
          </div>
        </div>
        <div v-if="!isAuthenticated" class="row">
          <div class="col-md-6">
            <b-button block class="main_button" @click="$router.push('/eligibilite')" variant="outline-danger" size="lg">
              <strong>Tester mon éligibilité et créer mon compte</strong>
            </b-button>
          </div>
          <div class="col-md-6 mt-md-0 mt-3">
            <b-button @click='clickOnDashboardButton' block class="main_button" variant="outline-danger" size="lg">
              <strong>Déposer et suivre ma demande</strong>
              </b-button>
          </div>
        </div>

        <div v-else class="row">
          <div class="col-12">
            <b-button @click='clickOnDashboardButton' block class="main_button"  variant="outline-danger" size="lg">
              <strong>Déposer et suivre ma demande</strong>
              </b-button>
          </div>
        </div>

      </div>
    </section>
    <hr>

    <section id='home-explanation'>
      <div class="container pt-2" >
        <!--<p>
          <strong>La campagne de bourses d'études pour l'année scolaire {{currentYear-1}}/{{currentYear}} est close.</strong>
        </p>-->
        <p class="mb-4">
          <strong> Date limite de dépôt des candidatures pour l'année scolaire {{currentYear}}/{{currentYear+1}} :
            <span class="text-danger">du 1<sup>er</sup>octobre {{currentYear}} au 31 décembre {{currentYear}}.</span></strong>
        </p>
        <ConditionsAttributionComponent />
        <hr>
        <helpComponent />
        <div class="row mt-4">
          <div class="col-12">
            <contactComponent />
          </div>
        </div>
      </div>
    </section>

    <cookie-law theme="blood-orange" buttonText="Accepter">
      <div slot="message">
        Le respect de votre vie privée est notre priorité - En poursuivant la navigation sur ce site,
        vous acceptez l'utilisation de cookies utilisés dans la seule fin d'améliorer votre expérience et
        de réaliser des statistiques d'audience anonymisés.
      </div>
    </cookie-law>

    <footerComponent />

  </div>
</template>

<script>
// @ is an alias to /src
const ConditionsAttributionComponent = () => import('@/components/ConditionsAttributionComponent')
const helpComponent = () => import('@/components/HelpComponent')
const contactComponent = () => import('@/components/contactComponent')
const footerComponent = () => import('@/components/footerComponent')
const CookieLaw = () => import('vue-cookie-law')

export default {
  name: 'Home',
  components: {
    ConditionsAttributionComponent,
    helpComponent,
    contactComponent,
    footerComponent,
    CookieLaw
  },
  data: function () {
    const now = new Date()
    const currentYear = now.getFullYear()
    return {
      currentYear: currentYear,
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
  },
  methods: {
    userGroupContains(groupsList) {
      var userGroups = this.$store.getters.getUserGroups
      var userGroupsArray = []
      var is_contained = false
      if (userGroups) {
        try {
          var userGroupsJson = JSON.parse(userGroups)
          for (var i = 0; i < userGroupsJson.length; i++) {
            userGroupsArray.push(
              userGroupsJson[i]['name']
            )
          }
        }
        catch(error) {
          this.$store.dispatch('AUTH_LOGOUT').then(() => {
            this.$router.push('/login')
            this.$bvToast.toast(`Votre session a expiré, veuillez vous reconnecter`, {
              title: 'Session expirée',
              autoHideDelay: 7000,
              appendToast: false
            })
          })
          return is_contained
        }
      }

      for (var j = 0; j < groupsList.length; j++) {
        if (userGroupsArray.indexOf(groupsList[j]) > -1) {
          is_contained = true
        }
      }
      return is_contained
    },

    clickOnDashboardButton () {
      if (this.userGroupContains(['BENEFICIAIRE'])) {
        this.$router.push('/beneficiaire-dashboard')
      }
      else if (this.userGroupContains(['CONSEILLER'])) {
        this.$router.push('/conseiller-dashboard')
      }
      else {
        this.$router.push('/login')
      }
    },
  }
}
</script>

<style>
.img {
  margin-right: -15px;
  margin-left: -15px;
}

#home-banner {
  background-size: cover;
  background-position:center;
  text-align: center;
  height: 150px;
  margin-top: 5px;
  /* Add the blur effect */
}


.banner-title {
  font-size: 24px;
  font-weight: bold;
}

.banner-slogan {
  font-size: 14px;
}

.main_button {
  font-size: 1rem;
}

@media (min-width: 480px) {
  #home-banner {
    height: 200px !important;
  }
  .banner-title {
    font-size: 28px;
  }
  .banner-slogan {
    font-size: 18px;
  }
}


@media (min-width: 768px) {
  #home-banner {
    height: 300px !important;
  }
  .banner-title {
    font-size: 28px !important;
  }
  .banner-slogan {
    font-size: 20px !important;
  }
  .main_button {
    font-size: 1.20rem;
  }
}

@media (min-width: 1024px) {
  #home-banner {
    height: 370px !important;
  }
  .banner-title {
    font-size: 30px !important;
  }
  .banner-slogan {
    font-size: 20px !important;
  }
  .main_button {
    font-size: 1.25rem;
  }
}

.footer_list{
    list-style-type:none;
    margin: 0;
    padding: 0;
}

.footer_list li{
    margin-bottom: 5px;
}

.footer_list a{
    color: black;
    text-decoration: none;
}

.footer_list a:hover {
    color: #BB3634;
    text-decoration: none;
}
</style>
