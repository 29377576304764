import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'

import Vuex from 'vuex'


import {
  LayoutPlugin, ModalPlugin, DropdownPlugin,
  NavPlugin, NavbarPlugin, ButtonPlugin,
  FormPlugin, FormCheckboxPlugin, FormGroupPlugin,
  FormInputPlugin, InputGroupPlugin, FormSelectPlugin,
  FormFilePlugin, FormTextareaPlugin, SpinnerPlugin,
  TablePlugin, TabsPlugin, ImagePlugin, FormRadioPlugin,
  FormDatepickerPlugin, PopoverPlugin
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'vue-awesome/icons/envelope'
import 'vue-awesome/icons/edit'
import 'vue-awesome/icons/dot-circle'
import 'vue-awesome/icons/times-circle'
import 'vue-awesome/icons/check-circle'
import 'vue-awesome/icons/times'
import 'vue-awesome/icons/trash-alt'
import 'vue-awesome/icons/cloud-download-alt'
import 'vue-awesome/icons/lock'
import 'vue-awesome/icons/arrow-right'
import 'vue-awesome/icons/info-circle'

import Icon from 'vue-awesome/components/Icon'

import browserDetect from "vue-browser-detect-plugin";

import VueDayjs from 'vue-dayjs-plugin'
import 'dayjs/locale/fr'

import VueGtag from "vue-gtag";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import VueMask from 'v-mask'

//import * as Sentry from "@sentry/vue";
//import { Integrations } from "@sentry/tracing";
//
//Sentry.init({
//  Vue,
//  dsn: "https://98a1ce0c3e9c40779ebcf81111373e63@o373339.ingest.sentry.io/5439935",
//  environment: process.env.NODE_ENV,
//  integrations: [
//    new Integrations.BrowserTracing({
//      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//      tracingOrigins: ["localhost", "audiens-bourses-etudes.org", /^\//],
//    }),
//  ],
//  tracesSampleRate: 1.0,
//});


Vue.use(VueGtag, {
  config: { id: "UA-161958571-6" }
}, router);


Vue.use(Vuex)

Vue.use(LayoutPlugin)
Vue.use(ModalPlugin)
Vue.use(DropdownPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(InputGroupPlugin)
Vue.use(SpinnerPlugin)
Vue.use(FormFilePlugin)
Vue.use(FormTextareaPlugin)
Vue.use(TablePlugin)
Vue.use(TabsPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ImagePlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(PopoverPlugin)

Vue.component('v-icon', Icon)

Vue.use(browserDetect)

Vue.use(VueDayjs)

Vue.use(VueMask)

Vue.component('loading', Loading)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
